<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    bottom
    left
    rounded="pill"
    :color="type"
    elevation="24"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false" rounded>
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "SnackBarService",

  data: () => ({
    snackbar: false,
    type: "success",
    message: "This is a message",
    timeout: 5000,
  }),

  created() {
    eventBus.$on("showSnackBar", (type, message, timeout) => {
      this.type = type;
      this.message = message;
      this.timeout = timeout;
      this.snackbar = true;
    });
  },
};
</script>
