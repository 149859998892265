import Vue from "vue";
import singleSpaVue from "single-spa-vue";

import App from "./App.vue";
// import AuthPlugin from "./plugins/authPlugin";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueQrcodeReader from "vue-qrcode-reader";
import eventPlugin from "./plugins/eventPlugin";
// import msalPlugin from "./plugins/msal-plugin";
import moment from "moment";

export const eventBus = new Vue();

Vue.config.productionTip = false;

// Vue.use(msalPlugin);
// Vue.use(AuthPlugin);
Vue.use(eventPlugin);
Vue.use(VueQrcodeReader);

Vue.mixin({
  created: function () {
    function getTitle(vm) {
      const { title } = vm.$options;
      if (title) {
        return typeof title === "function" ? title.call(vm) : title;
      }
    }

    const title = getTitle(this);
    if (title) {
      document.title = "YMS Admin - " + title;
    }
  },
});

Vue.filter("formatDatetimeFromNow", function (value) {
  let new_date = "";
  if (value) {
    new_date = moment(String(value)).fromNow();
  }
  return new_date;
});

Vue.filter("formatDatetime", function (value) {
  let new_date = "N/A";
  if (value) {
    new_date = moment(String(value)).fromNow();
  }
  return new_date;
});

Vue.filter("formatUSAshortDatetime", function (value) {
  let new_date = "N/A";
  if (value) {
    new_date = moment(String(value)).format("MM/DD/YY");
  }
  return new_date;
});

Vue.filter("formatUSAPhoneNumber", function (phoneNumber) {
  if (phoneNumber) {
    const countryCode = phoneNumber.slice(0, 1);
    const areaCode = phoneNumber.slice(1, 4);
    const firstPart = phoneNumber.slice(4, 7);
    const secondPart = phoneNumber.slice(7, 11);

    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  } else {
    return "";
  }
});

Vue.filter("formatDateFromReceipt", function (datetime) {
  if (!datetime) {
    return "";
  }

  const date = new Date(datetime);

  return date
    .toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(" 24:", " 00:"); // 24:00 -> 00:00
});

Vue.filter("formatDateFromBE", function (datetime) {
  if (!datetime) {
    return "";
  }
  const formattedDatetime = datetime.replace(" ", "T") + "Z";
  const date = new Date(formattedDatetime);

  return date
    .toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(" 24:", " 00:"); // 24:00 -> 00:00
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecycle-props
          // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        },
      });
    },
    router,
    store,
    vuetify,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
