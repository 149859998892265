import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/yms-portal",
    redirect: { name: "inyardstorage" },
  },
  {
    path: "/yms-portal/checkinout/:receiptid?",
    name: "checkinout",
    props: (route) => ({
      receiptid: route.params.receiptid,
      containerNumber: route.query.containerNumber,
      chassisNumber: route.query.chassisNumber,
      trailerNumber: route.query.trailerNumber,
      spot: route.query.spot,
      empty: route.query.empty,
      location: route.query.location,
      scacCode: route.query.scacCode,
    }),
    component: () =>
      import("../views/CheckInCheckOutViews/CheckInCheckOutView.vue"),
  },
  {
    path: "/yms-portal/gatelog",
    name: "gatelog",
    component: () => import("../views/GateLogViews/GateLogView.vue"),
  },
  {
    path: "/yms-portal/pending",
    name: "pending",
    component: () => import("../views/PendingsViews/PendingsView.vue"),
  },
  {
    path: "/yms-portal/yardstorage",
    name: "yardstorage",
    props: (route) => ({
      scac_code: route.query.scac_code,
      start_date: route.query.start_date,
      end_date: route.query.end_date,
      yard_location: route.query.yard_location,
    }),
    component: () => import("../views/YardStorageViews/YardStorageView.vue"),
  },
  {
    path: "/yms-portal/inyardstorage",
    name: "inyardstorage",
    props: (route) => ({
      scac_code: route.query.scac_code,
      start_date: route.query.start_date,
      end_date: route.query.end_date,
      yard_location: route.query.yard_location,
    }),
    component: () => import("../views/YardStorageViews/InYardStorageView.vue"),
  },
  {
    path: "/yms-portal/containershistory",
    name: "containershistory",
    props: (route) => ({
      scac_code: route.query.scac_code,
      start_date: route.query.start_date,
      end_date: route.query.end_date,
    }),
    component: () =>
      import("../views/ContainersHistoryViews/ContainersHistoryView.vue"),
  },
  {
    path: "/yms-portal/drivers",
    name: "drivers",
    component: () => import("../views/DriversViews/DriversView.vue"),
  },
  // {
  //   path: "/yms-portal/checkinoutcarrier/:receiptid?",
  //   name: "checkinoutcarrier",
  //   props: true,
  //   component: () =>
  //     import("../views/CheckInCheckOutViews/CheckInCheckOutCarrierView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.path !== "/" && !Vue.prototype.$auth.isSupraUser) {
//     next("/");
//   } else {
//     next();
//   }
// });

export default router;
