<template>
  <v-app>
    <SnackBarService />
    <!-- <AppNavBar /> -->
    <v-main class="grey lighten-4 overflow-x-hidden">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SnackBarService from "./components/Shared/SnackBarService.vue";
import VueCookies from "vue-cookies";
import Vue from "vue";
Vue.use(VueCookies);
export default {
  components: {
    SnackBarService,
  },
  name: "App",
  data() {
    return {
      loading: false,
      userDataListener: null,
    };
  },
  created() {},
  methods: {},
};
</script>
